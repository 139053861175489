import * as React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import { mediaWidth, zIndex } from '../../../styles/layout'
import { fontSize } from '../../../styles/font'
import { IContentfulWork } from '../../../common'
import { CategoryFilterContext } from '../../../pages/work'

const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
`

const Item = styled.li`
  width: 100%;
`

const ItemLink = styled(Link)`
  display: block;
  transition: opacity .5s;
  text-decoration: none;
  position: relative;
  &:after {
    background-color: #000;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    opacity: 0;
    transition: opacity .5s;
  }
  &:hover {
    :after {
      opacity: .5;
    }
    & > p {
      opacity: 1;
    }
  }
`

const WorkImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    display: block;
    padding-top: 60%;
  }
`

const WorkImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const WorkTitle = styled.p`
  color: #fff;
  font-size: ${ fontSize.px20 };
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  transition: opacity .5s;
  z-index: ${ zIndex.item };
  width: 100%;
`

interface IProps {
  works: IContentfulWork
}

const AllWorkList: React.FC<IProps> = ({ works }) => {
  const { filterCategory } = React.useContext(CategoryFilterContext)
  const isIncludeCategory = (categories: [{ name: string }]) => !filterCategory || categories.some(({ name }) => name === filterCategory)
  return (
    <Wrapper>
      { works.edges.map((work, i) => {
        const src = work.node.main.sizes.src.replace(/\?.*$/, '')
        return (isIncludeCategory(work.node.category) && (
          <Item key={ i }>
            <ItemLink to={ `/work/${ work.node.contentful_id }.html` }>
              <WorkImageWrapper>
                <WorkImage src={ work.node.main.sizes.src } />
              </WorkImageWrapper>
              <WorkTitle>{ work.node.name }</WorkTitle>
            </ItemLink>
          </Item>
        ))
      })
      }
    </Wrapper>
  )
}

export default AllWorkList
