import * as React from 'react'
import { GlobalStyles } from '../../../styles/global'
import styled from 'styled-components'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import { IThemeDetail, themes } from '../../../styles/theme'
import { mediaWidth, spSpace } from '../../../styles/layout'
import customFadeInWrapper from '../../hoc/intersectionAnimation/customFadeInWrapper'
import WorkList from '../../organisms/WorkList'
import { IContentfulWork } from '../../../common'
import { fontSize } from '../../../styles/font'
import CategoryFilterLinks from '../../organisms/CategoryFilterLinks'

const Contents = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
`

interface IWrapper {
  theme: IThemeDetail
}

const Wrapper = styled.div`
  background-color: ${ ({ theme }: IWrapper) => theme.background };
  display: flex;
  justify-content: center;
`

const Inner = styled.div`
  box-sizing: border-box;
  padding: 100px 0;
  width: 85vw;
  max-width: 1160px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding: 50px ${ spSpace.outline.side }px;
    width: 100%;
  }
`

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 75px;
  min-height: 100vh;
`

const SectionInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 50px;
`

interface ITheme {
  theme: IThemeDetail
}

const PageTitle = styled.h1`
  color: ${ ({ theme }: ITheme) => theme.color };
  font-size: ${ fontSize.px50 };
  font-weight: bold;
  line-height: 1;
  text-align: center;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px36 };
  }
`

const SubTitle = styled.p`
  color: ${ ({ theme }: ITheme) => theme.color };
  font-size: ${ fontSize.px16 };
  font-weight: normal;
  line-height: 1;
  margin-top: 10px;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    font-size: ${ fontSize.px16 };
  }
`

const FadeInWrapper = customFadeInWrapper({})

interface IProps {
  works: IContentfulWork
  categories: string[]
}

const WorkListLayout: React.FC<IProps> = ({ works, categories }) => (
  <>
    <GlobalStyles />
    <Header />
    <Contents>
      <Wrapper theme={ themes.white } id="work">
        <Inner>
          <FadeInWrapper>
            <SectionWrapper>
              <PageTitle>WORK<SubTitle>There is past performance.</SubTitle></PageTitle>
              <SectionInner>
                <CategoryFilterLinks categories={ categories } />
                <WorkList theme={ themes.white } works={ works } />
              </SectionInner>
            </SectionWrapper>
          </FadeInWrapper>
        </Inner>
      </Wrapper>
    </Contents>
    <Footer />
  </>
)

export default WorkListLayout
