import * as React from 'react'
import styled from 'styled-components'
import { fontSize } from '../../../styles/font'
import { CategoryFilterContext } from '../../../pages/work'
import { mediaWidth } from '../../../styles/layout'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const WrapperUl = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    width: 100%;
  }
`

const Item = styled.li`
  padding: 10px 30px;
  text-align: center;
  @media screen and (max-width: ${ mediaWidth.sp }px) {
    padding: 10px 0; 
    width: calc(100%/3);
  }
`

interface IFilterButton {
  active?: boolean
}

const FilterButton = styled.button`
  color: ${ ({ active = false }: IFilterButton) => active ? '#333' : '#ccc' };
  font-size: ${ fontSize.px16 };
  position: relative;
  transition: color .5s;
  &:after {
    background-color: #333;
    content: '';
    display: block;
    height: 1px;
    width: ${ ({ active = false }: IFilterButton) => active ? 100 : 0 }%;
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    margin: auto;
    transition: width .5s;
  }
  &:hover {
    color: #333;
    &:after {
      width: 100%;
    }
  }
`

interface IProps {
  categories: string[]
}

const CategoryFilterLinks: React.FC<IProps> = ({ categories }) => {
  const { filterCategory, setFilterCategory } = React.useContext(CategoryFilterContext)
  const reset = () => setFilterCategory('')

  return (
    <Wrapper>
      <WrapperUl>
        <Item>
          <FilterButton
            active={ !filterCategory }
            onClick={ reset }
          >ALL</FilterButton>
        </Item>
        { categories.map((category, i) => {
          const onClick = () => setFilterCategory(category)
          return (
            <Item key={ i }>
              <FilterButton
                active={ category === filterCategory }
                onClick={ onClick }
              >{ category }</FilterButton>
            </Item>
          )
        }) }
      </WrapperUl>
    </Wrapper>
  )
}

export default CategoryFilterLinks