import * as React from 'react'
import { graphql } from 'gatsby'
import WorkListLayout from '../components/templates/WorkListLayout'
import { IContentfulCategory, IContentfulWork } from '../common'

const getParams = () => {
  if (typeof location !== 'undefined' && location.search) {
    const paramList = location.search.split('?')[1].split('&')
    return paramList.reduce((accum: Map<string, string>, current) => {
      const [key, value] = current.split('=')
      accum.set(key, value)
      return accum
    }, new Map)
  }
  return new Map
}

interface IProps {
  data: {
    allContentfulWork: IContentfulWork
    allContentfulCategory: IContentfulCategory
  }
}

interface ICategoryFilterContext {
  filterCategory: string
  setFilterCategory: (a: string) => void
}

export const CategoryFilterContext = React.createContext<ICategoryFilterContext>({
  filterCategory: '',
  setFilterCategory: () => false,
})
export const CategoryFilterProvider = CategoryFilterContext.Provider

const Provider: React.FC = ({ children }) => {
  const params = getParams()
  const defaultCategory = params.get('category')
  const [filterCategory, setFilterCategory] = React.useState(defaultCategory)

  return (
    <CategoryFilterProvider value={ { filterCategory, setFilterCategory } }>
      { children }
    </CategoryFilterProvider>
  )
}

const parseCategories = (category: IContentfulCategory) => category.edges.map((item) => item.node.name)

const IndexPage: React.FC<IProps> = ({ data: { allContentfulWork, allContentfulCategory } }) => {
  const categories = parseCategories(allContentfulCategory)
  return (
    <Provider>
      <WorkListLayout works={ allContentfulWork } categories={ categories } />
    </Provider>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query WorkPageQuery {
    allContentfulWork {
      edges {
        node {
          name
          contentful_id
          main {
            sizes {
              src
            }
          }
          subset {
            sizes {
              src
            }
          }
          category {
            name
          }
        }
      }
    }
    allContentfulCategory(sort: {fields: priority, order: DESC}) {
      edges {
        node {
          name
          priority
        }
      }
    }
  }
`
