import * as React from 'react'
import { IThemeDetail } from '../../../styles/theme'
import customFadeInWrapper from '../../hoc/intersectionAnimation/customFadeInWrapper'
import { IContentfulWork } from '../../../common'
import AllWorkList from '../../molecules/AllWorkList'

const FadeInWrapper = customFadeInWrapper({ delay: 500 })

interface IProps {
  theme: IThemeDetail
  works: IContentfulWork
}

const WorkList: React.FC<IProps> = ({ theme, works }) => (
  <FadeInWrapper>
    <AllWorkList works={ works } />
  </FadeInWrapper>
)

export default WorkList